export const fontSizes = {
  xsmall: '.8rem',
  small: '1.6rem',
  medium: '2.4rem',
  large: '3.2rem',
  xlarge: '4.0rem',
  xxlarge: '4.8rem',
  huge: '5.6rem',
  xhuge: '6.4rem',
};
